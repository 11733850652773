<template>
	<div class="Members">
		
		<h3>
			<i>成员列表</i>
			<span>
				<em>
				<el-select>
					<el-option>所有部门</el-option>
				</el-select>
				</em>
				<em>
				<el-select>
					<el-option>所有角色</el-option>
				</el-select>
				</em>
				
				<em>
					<el-input placeholder="昵称"></el-input>
				</em>
				
				<em>
					<el-input placeholder="编号"></el-input>
				</em>
				<el-button icon="el-icon-search">查询</el-button>
				<div class="New">
					<el-button type="primary">添加成员</el-button>
				</div>
			</span>
		</h3>

		<div class="List">
			
			<div class="Title">
				<span class="Name">昵称</span>
				<span class="Department">角色</span>
				<span class="Id">会员编号</span>
				<span class="Mobile">手机</span>
				<span class="Action">操作</span>
			</div>
			
			<div class="Mem" v-for="item in 16" :key="item">
				<span class="Name">阿莲</span>
				<span class="Department">采购员</span>
				<span class="Id">H122121</span>
				<span class="Mobile">133****7712</span>
				<span class="Action">
					<em>编辑</em>
					<em>删除</em>
				</span>
			</div>
			
		</div>
		
		<div class="Page">
			<el-pagination
			  background
			  layout="prev, pager, next"
			  :total="1000">
			</el-pagination>
		</div>

	</div>
</template>

<script>
	import {Select,Option,Dropdown,DropdownMenu,DropdownItem,Radio,Pagination} from 'element-ui'
	import DepartmentItem from '@/components/organization/DepartmentItem.vue'
	export default {
	  name: 'DepartmentMembers',
	  props: {
	  },
	  data() {
	      return {
			  Departments:[
				{Id:'a1',Name:'技术部',MemberCount:3,Children:[]},
				{Id:'a2',Name:'设计部',MemberCount:12,Children:[]},
				{Id:'a3',Name:'研发部',MemberCount:12,Children:[{Id:'a4',Name:'研发1部',MemberCount:113,Children:[]},{Id:'a5',Name:'研发2部',MemberCount:62,Children:[]}]}
			  ]
	      }
	  },
	  components: {
		'el-dropdown':Dropdown,
		'el-dropdown-menu':DropdownMenu,
		'el-dropdown-item':DropdownItem,
		'el-select':Select,
		'el-option':Option,
		'el-radio':Radio,
		DepartmentItem,
		'el-pagination':Pagination
	  },
	  methods:{
	  }
	}
</script>

<style scoped>
.Members{
	background-color: #FFFFFF;
	margin: 20px;
	padding: 30px;
}
.Members h3{
	display: flex;
	align-items: center;
	border-bottom: 1px solid rgba(0,0,0,0.1);
	padding-bottom: 20px;
}
.Members h3 i{
	width: 100px;
	font-style: normal;
}
.Members h3 span{
	flex: 1;
	display: flex;
}
.Members h3 span em{
	margin-right: 5px;
}
.Members h3 .New{
	flex: 1;
	text-align: right;
}
.Members .List{
	border: 1px solid rgba(0,0,0,0.1);
	border-bottom: 0px;
}
.Members .List .Title,.Members .List .Mem{
	display: flex;
	border-bottom: 1px solid rgba(0,0,0,0.1);
	padding: 15px 0px;
}
.Members .List .Title{
	color: rgba(0,0,0,0.3);
	padding: 15px 0px;
	background-color: rgba(0,0,0,0.02);
}
.Members .List .Mem:hover{
	background-color: rgba(228,0,0,0.1);
}
.Members .Name{
	width: 20%;
	margin-left: 20px;
}
.Members .Department{
	width: 20%;
}
.Members .Id{
	width: 20%;
}
.Members .Mobile{
	width: 20%;
}
.Members .Action{
	flex: 1;
	text-align: right;
	margin-right: 20px;
}
.Members .Action em{
	margin-left: 10px;
	cursor: pointer;
}
.Members .Action em:hover{
	color: rgba(228,0,0,1);
}
</style>
