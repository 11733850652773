<template>
	<div class="DepartmentItem">
		
		<div class="Info" v-for="(item,itemI) in departments" :key="itemI">
			
			<span class="Name"> 
				<i class="el-icon-arrow-right"></i>
				{{item.Name}}
				<em><el-button size="mini" circle icon="el-icon-edit"></el-button></em>
				<em><el-button size="mini" circle icon="el-icon-delete"></el-button></em>
			</span>
			<span class="Action">
				<i class="ShowMembers"><el-button size="mini">查看</el-button></i>
			</span>
			<span class="Members">
				{{item.MemberCount}}人
			</span>
			
			<template v-if="item.Children.length > 0">
				<div class="Children">
					<div class="Space">
					</div>
					<div class="Child">
						<DepartmentItem :departments="item.Children"></DepartmentItem>
					</div>
				</div>
			</template>
			
			
		</div>
		
	

	</div>
</template>

<script>
	import {Select,Option,Dropdown,DropdownMenu,DropdownItem,Radio} from 'element-ui'
	export default {
	  name: 'DepartmentItem',
	  props: {
	  		departments:{
	  			type:Array,
	  			default:function(){
	  				return []
	  			}
	  		},
	  },
	  data() {
	      return {
	      }
	  },
	  components: {
		'el-dropdown':Dropdown,
		'el-dropdown-menu':DropdownMenu,
		'el-dropdown-item':DropdownItem,
		'el-select':Select,
		'el-option':Option,
		'el-radio':Radio
	  },
	  methods:{
	  }
	}
</script>

<style scoped>
.DepartmentItem{
	width: 100%;
}
.DepartmentItem .Info{
	width: 100%;
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	border-top: 1px solid rgba(0,0,0,0.1);
	line-height: 55px;
}
.DepartmentItem .Info .ShowMembers{
	display: none;
}
.DepartmentItem .Info:hover{
	background-color: rgba(228,0,0,0.05);
}
.DepartmentItem .Info:hover .ShowMembers{
	display: inline-block;
}
.DepartmentItem .Children{
	width: 100%;
	display: flex;
}
.DepartmentItem .Children .Space{
	width: 35px;
}
.DepartmentItem .Children .Child{
	flex: 1;
}
.DepartmentItem .Name{
	flex: 1;
	padding-left: 20px;
}
.DepartmentItem .Name em{
	margin-left: 10px;
	display: none;
}
.DepartmentItem .Info:hover .Name em{
	display: inline-block;
}
.DepartmentItem .Members{
	margin-right: 10px;
	text-align: right;
	color: #999999;
	font-size: 0.9rem;
	margin-right: 20px;
}
.DepartmentItem .Action{
	margin-right: 5px;
}
</style>
